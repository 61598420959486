import React from 'react'
import PropTypes from 'prop-types'
import Metadata from '../components/Metadata'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import { themeStyles } from '../utils/theme'
import useSiteMetadata from '../components/SiteMetadata'

export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  helmet,
}) => {
  const PostContent = contentComponent || Content

  return (
    <section>
      {helmet || ''}
      <h1 css={themeStyles.sectionHeader}>
        {title}
      </h1>
      <div css={{ ...themeStyles.contentWidth, ...themeStyles.textPadding }}>
        <p>{description}</p>
        <PostContent content={content} />
      </div>
    </section>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data
  const { siteUrl } = useSiteMetadata()
  return (
    <Layout metadataTitle={post.frontmatter.title} metadataDescription={post.frontmatter.description || post.frontmatter.title}>
      <BlogPostTemplate
        content={post.htmlAst}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Metadata
            titleTemplate={`%s | Blog`}
            title={post.frontmatter.title}
            description={post.frontmatter.description}
            image={`${siteUrl}${post.frontmatter.featuredImage.childImageSharp.gatsbyImageData.src}`}
          />
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      htmlAst
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        featuredImage {
          childImageSharp {
            gatsbyImageData(width: 500, quality: 100, layout: FIXED)
          }
        }
      }
    }
  }
`
